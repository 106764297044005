<template>
  <div class="page_con">
    <div class="form_wrap">
      <div class="form">
        <ul class="form_ul">
          <li class="form_li">
            <div class="form_ttl">휴대폰으로 찾기</div>
            <div class="form_in">
              <div class="inline">
                <label class="input">
                  <input
                    type="number"
                    inputmode="decimal"
                    pattern="\d*"
                    placeholder="휴대폰 번호(숫자만 입력)"
                    :value="phone"
                    @input="maskingPhone"
                  />
                </label>
                <button class="btn" @click="fnSendAuthValue">
                  {{ sendCnt === 0 ? '발송' : '재발송' }}
                </button>
              </div>
              <div class="inline">
                <label class="input">
                  <input
                    type="number"
                    inputmode="decimal"
                    pattern="\d*"
                    placeholder="인증번호(6자리 숫자)"
                    :value="authValue"
                    @input="maskingAuthValue"
                    :disabled="$Util.isEmpty(remainTime)"
                  />
                </label>
                <div class="time_count end" v-if="!$Util.isEmpty(remainTime)">
                  입력 시간 {{ remainTime }}
                </div>
                <!--'종료: end'-->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="con_btn">
      <div class="btn_wrap">
        <button class="btn" @click="fnNext">회원정보 인증</button>
        <div class="hint">
          * 카카오, 네이버, 구글 ID 가입 회원은 각 서비스에서 문의해주세요.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-find-id-form-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
