import { reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    secondTime: 0,
    endTime: '',
    sendCnt: 0,
    phone: '',
    authValue: '',
    timer: undefined,
    remainTime: ''
  })
  const fnSendAuthValue = async () => {
    if (proxy.$Util.isEmpty(state.phone)) {
      alert('휴대폰번호를 입력해주세요.')
      return
    }
    const params = {}
    params.phone = state.phone
    const res = await proxy.$UserSvc.postFindIdSendAuthValue(params)
    if (res.resultCode === '0000') {
      alert(`인증번호가 ${state.sendCnt === 0 ? '발송' : '재발송'}되었습니다.`)
      state.sendCnt++
    } else {
      alert(res.resultMsg)
    }
  }
  /** watch **/
  watch(
    () => state.sendCnt,
    () => {
      if (!proxy.$Util.isEmpty(state.timer)) {
        clearInterval(state.timer)
        state.timer = undefined
        state.remainTime = ''
      }
      state.endTime = proxy
        .$dayjs()
        .add(
          proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND * 1000,
          'millisecond'
        )
      state.secondTime = proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND
      fnFormatRemainTime()
      state.timer = setInterval(() => {
        --state.secondTime
      }, 1000)
    }
  )
  watch(
    () => state.secondTime,
    val => {
      if (val < 1) {
        clearInterval(state.timer)
        state.timer = undefined
        state.remainTime = ''
        state.authValue = ''
        alert('인증시간 초과 하였습니다.')
      } else {
        fnFormatRemainTime()
      }
    }
  )

  const maskingPhone = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 15) {
      e.target.value = e.target.value.substr(0, 15)
    }
    state.phone = String(e.target.value)
  }

  const maskingAuthValue = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 6) {
      e.target.value = e.target.value.substr(0, 6)
    }
    state.authValue = String(e.target.value)
  }

  const fnNext = async () => {
    if (!isValid()) return
    const params = {}
    params.phone = state.phone
    params.authValue = state.authValue
    const res = await proxy.$UserSvc.postGetIdByPhone(params)
    if (res.resultCode === '0000') {
      await router.push({
        name: 'find-id-done-index',
        params: { userId: res.userId }
      })
    } else {
      alert(res.resultMsg)
    }
  }
  const fnFormatRemainTime = () => {
    const now = proxy.$dayjs()
    const minute = state.endTime.diff(now, 'minute')
    const second = state.endTime.diff(now, 'second')
    state.remainTime = `${proxy.$Util.lpad(
      `${minute % 60}`,
      2,
      '0'
    )}:${proxy.$Util.lpad(`${second % 60}`, 2, '0')}`
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.authValue)) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    if (state.authValue.length !== 6) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    return true
  }
  return {
    ...toRefs(state),
    fnNext,
    fnSendAuthValue,
    maskingAuthValue,
    maskingPhone
  }
}
